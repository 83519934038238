import Link from "next/link";
import { ICart } from "@/interfaces/cart";
import { isEmpty } from "lodash";
import { Drawer } from "antd";
import { LiaTimesSolid } from "react-icons/lia";
import { FaTimes } from "react-icons/fa";
import { Image } from "@nextui-org/react";
import EmptyCart from "@/components/Shares/EmptyCart";
import { fetchDeleteCart } from "@/store/slices/cartSlice";
import { useAppDispatch } from "@/hooks/useRedux";
import { formatPrice } from "@/utils/format";

interface CartDrawerProps {
  cart: ICart | null;
  cartOpen: boolean;
  setCartOpen: (value: boolean) => void;
}

const CartDrawer: React.FC<CartDrawerProps> = (props) => {
  const { cart, cartOpen, setCartOpen } = props;
  const onClose = () => {
    setCartOpen(false);
  };
  const dispatch = useAppDispatch();
  const handleDelete = (id: number) => {
    if (cart) {
      const payload = {
        productVariantId: id,
        shoppingCartId: cart.id,
      };
      dispatch(fetchDeleteCart(payload));
    }
  };
  return (
    <Drawer
      title="Review Cart"
      placement="right"
      width={368}
      open={cartOpen}
      onClose={onClose}
    >
      <div className="h-full">
        {cart && !isEmpty(cart.shoppingCartItems) ? (
          <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
              {cart.shoppingCartItems.map((item) => {
                const { id, quantity, productVariant, isAssembly } = item;
                const { name, images, price } = productVariant;
                return (
                  <div
                    key={id}
                    className="flex items-center gap-6 w-full justify-between"
                  >
                    <div className="flex gap-4">
                      <Link href={`/product/${productVariant.product.slug}`}>
                        <div className="h-20 w-20 flex items-center justify-center bg-gray-100">
                          <Image
                            src={images[0]}
                            alt={name}
                            isZoomed
                            radius="none"
                          />
                        </div>
                      </Link>
                      <div>
                        <Link href={`/product/${productVariant.product.slug}`}>
                          <h3 className="font-bold hover:text-icon transition-all duration-500">
                            {name}
                          </h3>
                        </Link>
                        <p className="flex items-center gap-1 mt-1">
                          {quantity}
                          <LiaTimesSolid />
                          <span className="text-icon">{formatPrice(price)} </span>
                          {isAssembly && (
                            <span> + {formatPrice(productVariant.assemblyFee)}</span>
                          )}
                        </p>
                      </div>
                    </div>
                    <FaTimes
                      className="text-red-600 cursor-pointer"
                      onClick={() => handleDelete(productVariant.id)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="border-stroke border-t py-4">
              <div className="flex justify-between text-lg">
                <span>Total:</span> <span>{formatPrice(cart.total)}</span>
              </div>
              <div
                className="select-none cursor-pointer bg-white border border-black w-full mt-4 text-lg  hover:bg-icon hover:border-icon hover:text-white rounded-md py-3 flex items-center justify-center"
                onClick={() => setCartOpen(false)}
              >
                Continue Shopping
              </div>
              <Link href="/checkout">
                <div
                  className="select-none bg-gray-950 w-full mt-4 text-lg text-white hover:bg-icon rounded-md py-3 flex items-center justify-center"
                  onClick={() => setCartOpen(false)}
                >
                  Checkout
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <EmptyCart setCartOpen={setCartOpen} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default CartDrawer;
