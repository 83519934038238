import Blog from "./components/Blog";
import BlogImage1 from "@/assets/homepage/blog1.jpg";
import BlogImage2 from "@/assets/homepage/blog2.jpg";

const Story = () => (
  <div className="flex flex-col items-center container mx-auto py-8 md:py-16 transition-all duration-500">
    <h2 className="text-4xl">Latest stories</h2>
    <p className="mt-4">
      Transform Your Space: Discover Unique Furniture Ideas and Trends
    </p>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6 transition-all duration-500 ">
      <Blog
        type="LIFESTYLE"
        author="Ryan Li"
        date="May 16, 2024"
        title="Perfect Furniture Pairing: Enhance Your Space with Style"
        description="Stylish ideas for perfect furniture pairing. Creating a cohesive and stylish living space can be a delightful yet challenging task...."
        link="/blog/perfect-furniture-pairing-enhance-your-space-with-style"
        image={BlogImage1.src}
      />
      <Blog
        type="LIFESTYLE"
        author="Ryan Li"
        date="May 27, 2024"
        title="Transform Your Living Space: The Ultimate Guide to Modern Furniture Trends"
        description="Discover how modern furniture trends can transform your living space into a stylish and functional haven...."
        link="/blog/transform-your-living-space-the-ultimate-guide-to-modern-furniture-trends"
        image={BlogImage2.src}
      />
    </div>
  </div>
);

export default Story;
