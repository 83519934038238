import { ReactNode, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

interface CollapseProps {
  title: string;
  detail: ReactNode;
}

const Collapse: React.FC<CollapseProps> = (props) => {
  const { title, detail } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleCollapsible = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="py-6 border-b ">
      <div
        className="flex justify-between  cursor-pointer"
        onClick={toggleCollapsible}
      >
        <h4 className="text-xl">{title}</h4>
        <button
          className="transform transition-transform duration-700 rounded-full h-7 w-7 font-black text-2xl flex justify-center items-center"
          style={{ transform: `scale(${expanded ? 1.2 : 1})` }}
          aria-label="Extend button"
        >
          {expanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </div>
      <div
        className={`transition-all duration-700 overflow-hidden ${
          expanded ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
        }`}
      >
        <div className="py-3">{detail}</div>
      </div>
    </div>
  );
};

export default Collapse;
