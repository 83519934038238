import { isValidEmail } from "@/utils/check";
import { useState } from "react";
import { notify } from "../Notification";
import { subscribe } from "@/service/subscribe";
import { Button } from "@nextui-org/react";

const Subscription = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      notify("Email is invalid", "error");
      return;
    }

    const res = await subscribe(email);

    if (res.statusCode === 200) {
      notify(
        "Thank you for subscription! You will get noticed about our latest news and special offers. A special discount code will be sent to your email.",
        "success",
      );
    } else {
      notify(res.data.message, "error");
    }
  };

  return (
    <div className="flex rounded overflow-hidden border border-gray-300 select-none">
      <input
        className="bg-opacity-90 w-full px-4 py-2 min-w-36 focus:outline-icon"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        className="bg-gray-950 w-16 hover:bg-icon transition-all duration-500 text-white p-6"
        onClick={handleSubmit}
        radius="none"
      >
        Subscribe
      </Button>
    </div>
  );
};

export default Subscription;
