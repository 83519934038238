import { Button, Image } from "@nextui-org/react";
import {
  ChangeEvent,
  useEffect,
  useState,
  MouseEvent,
  useCallback,
} from "react";
import { IoIosSearch } from "react-icons/io";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { debounce, isEmpty } from "lodash";
import { searchProduct } from "@/service/product";
import { IProduct } from "@/interfaces/product";
import { useRouter } from "next/router";
import Link from "next/link";
import { formatPrice } from "@/utils/format";

interface SearchProductProps {
  resultNumber: number;
  onClose?: () => void;
}

const SearchProduct: React.FC<SearchProductProps> = (props) => {
  const { resultNumber, onClose } = props;
  const [focus, setFocus] = useState(false);
  const [searchWords, setSearchWords] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchProducts, setSearchProducts] = useState<IProduct[]>([]);

  const router = useRouter();

  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchWords(e.target.value);
  };

  const handleRouterPush = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose && onClose();
    router.push(`/search?str=${searchWords}`);
  };

  const fetchData = async (query: string) => {
    setLoading(true);
    const res = await searchProduct(query);
    if (res.statusCode === 200) {
      setSearchProducts(res.data);
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((query: string) => {
      fetchData(query);
    }, 300),
    [],
  );

  useEffect(() => {
    if (searchWords.length >= 3) {
      debouncedFetchData(searchWords);
    }
  }, [searchWords, debouncedFetchData]);

  return (
    <>
      <div
        className={`flex flex-1 max-w-[600px] w-full border rounded-sm pl-4 py-1 select-none ${
          focus ? `border-icon` : `outline-none`
        }`}
      >
        <input
          className="border-none flex-1 outline-none"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={searchWords}
          onChange={handleKeywordChange}
          placeholder="Search"
        />
        <Button
          className="bg-transparent min-w-12 px-0"
          isLoading={loading}
          onClick={handleRouterPush}
        >
          {!loading && <IoIosSearch className="text-xl hover:text-icon" />}
        </Button>
      </div>
      <div className="max-w-[600px] w-full mt-1">
        {searchWords.length < 3 ? (
          <small>Please enter at least 3 characters to search...</small>
        ) : isEmpty(searchProducts) ? (
          <p>No Product Found</p>
        ) : (
          <div className="flex flex-col items-start">
            {searchProducts.slice(0, resultNumber).map((searchProduct) => {
              const { id, name, productVariants, slug } = searchProduct;
              return (
                <div key={id} className="flex gap-5 border-b py-3 w-full">
                  <Link href={`/product/${slug}`} onClick={onClose}>
                    <div className="w-16 flex items-center justify-center">
                      <Image
                        alt={name}
                        src={productVariants[0].images[0]}
                        isZoomed
                        radius="none"
                      />
                    </div>
                  </Link>
                  <div>
                    <Link
                      href={`/product/${slug}`}
                      className="hover:text-icon"
                      onClick={onClose}
                    >
                      <p>{name}</p>
                    </Link>
                    <div className="flex gap-2">
                      <p className="text-icon">${productVariants[0].price}</p>
                      {productVariants[0].oldPrice !== 0 && (
                        <div className="flex gap-2">
                          <p
                            className={"line-through text-gray-500 font-light"}
                          >
                            <span>{formatPrice(productVariants[0].oldPrice)}</span>
                          </p>
                          <div className="bg-red-500 text-white px-2 rounded-md">
                            <p>
                              -
                              {Math.round(
                                ((productVariants[0].oldPrice -
                                  productVariants[0].price) *
                                  100) /
                                  productVariants[0].oldPrice,
                              )}
                              %
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <Button
              className="bg-gray-950 hover:bg-icon transition-all duration-500 text-white rounded p-6 mt-2"
              onClick={handleRouterPush}
            >
              Show All <MdOutlineArrowForwardIos />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchProduct;
