import Image from "next/image";
import TouchImg from "@/assets/homepage/touch.jpg";
import Subscription from "@/components/Shares/Subscription";

const Touch = () => {
  return (
    <div className="relative w-full overflow-hidden">
      <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-90 w-full max-w-[750px] py-16 px-8">
        <div className="flex flex-col items-center">
          <h3 className="text-3xl text-center">
            Subscribe now to get a $30 discount code!
          </h3>
          <p className="mt-3 text-center">
            Enjoy a $30 discount on your first purchase over $199 (excluding
            shipping), and receiving our latest news and special offers.
          </p>
          <div className="mt-6">
            <Subscription />
          </div>
          <p className="text-center px-4 mt-6">
            We respect your privacy, so we never share your info.
          </p>
        </div>
      </div>
      <div className="relative w-full h-[430px]">
        <Image
          src={TouchImg.src}
          alt="hero"
          width={1300}
          height={896}
          className="absolute inset-0 w-full h-full object-cover object-center"
        />
      </div>
    </div>
  );
};

export default Touch;
