import sku2020712 from "@/components/Shares/CarouselSlider/images/carouselSlider/2020712.webp";
import sku3020121 from "@/components/Shares/CarouselSlider/images/carouselSlider/3020121.webp";
import sku2030361 from "@/components/Shares/CarouselSlider/images/carouselSlider/2030361.webp";
import sku3010296 from "@/components/Shares/CarouselSlider/images/carouselSlider/3010296.webp";
import sku1020760 from "@/components/Shares/CarouselSlider/images/carouselSlider/1020760.webp";
import sku3050174 from "@/components/Shares/CarouselSlider/images/carouselSlider/3050174.webp";
import sku3020420 from "@/components/Shares/CarouselSlider/images/carouselSlider/3020420.webp";

const slideData = [
  {
    sku: "2020712",
    imageSrc: sku2020712.src,
    title: "Redfern",
    description:
      "The Redfern 3-Door Combo Mirrored Wardrobe is a contemporary style product.",
    link: "/product/redfern-melamine-3-door-combo-with-mirror",
  },
  {
    sku: "3020121",
    imageSrc: sku3020121.src,
    title: "Daisy",
    description:
      "The Daisy Lowboy in walnut color exudes an aura of timeless elegance and classic sophistication.",
    link: "/product/daisy-buffet-lowboy",
  },
  {
    sku: "2030361",
    imageSrc: sku2030361.src,
    title: "Coogee",
    description:
      "The Coogee Bedside Table combines contemporary design with practical functionality, making it a stylish addition to any bedroom.",
    link: "/product/coogee-bedside-table",
  },
  {
    sku: "3010296",
    imageSrc: sku3010296.src,
    title: "Simon",
    description:
      "The Simon Coffee Table, a sophisticated addition to your living space that blends elegance with practicality.",
    link: "/product/simon-coffee-table-cement-top",
  },
  {
    sku: "1020760",
    imageSrc: sku1020760.src,
    title: "Matthew",
    description:
      "Manufactured with a sturdy MDF timber frame for lasting durability and stability.",
    link: "/product/matthew-plush-velvet-fabric-bed-double-dark-grey",
  },
  {
    sku: "3050174",
    imageSrc: sku3050174.src,
    title: "Lindy",
    description:
      "This beautiful sofa bed converts into a double bed with a few simple steps, thanks to the easy-to-use click-clack mechanism.",
    link: "/product/lindy-timber-arm-sofa-bed",
  },
  {
    sku: "3020420",
    imageSrc: sku3020420.src,
    title: "Maya",
    description:
      "The Maya Dining Drop Leaf Table is meticulously crafted for the modern home.",
    link: "/product/maya-dining-table",
  },
];

export default slideData;
